<template>
  <section class="Programas text-center" data-aos="fade-down">
    <img
      src="@/assets/img/top-curva-desktop.png"
      class="w-100"
      alt="Fondo programas 1 (desktop)"
      loading="lazy"
    />
    <div class="pb-5 pt-4 container-wetforest bg-light-green">
      <h2 class="fw-bold text-center mb-5">{{ data.title }}</h2>

      <div
        class="row m-0 mb-3 container-programa align-items-center"
        v-for="card in data.cards"
        :key="card.id"
      >
        <div class="w-100 p-3 contain-title d-none d-md-block">
          <h5 class="card-title mb-0 text-start">
            {{ card.title }}
          </h5>
        </div>
        <div class="col-12 col-md-4 py-5">
          <img
            id="programaAssessmentImagen"
            :src="require(`../../assets/${card.img}`)"
            :alt="`Imagen de programa ${card.title}`"
            loading="lazy"
            class="program-image"
          />
        </div>
        <h5 class="d-block card-title d-md-none">
          {{ card.title }}
        </h5>
        <div class="col-12 col-md-8 pt-3">
          <div class="contain-info py-4">
            <div class="w-100 pe-3 text-start">
              <div class="container" v-if="card.content">
                <div v-html="card.content"></div>
              </div>

              <ul v-if="card.list">
                <li class="mb-2" v-for="item in card.list" :key="item.id">
                  <p class="m-0 list-item">{{ item.text }}</p>
                </li>
              </ul>
              <div class="row m-0 my-5">
                <div class="col-12 col-md-6 mb-4 mb-md-0" v-if="card.seeMore">
                  <p class="body2">
                    {{ card.seeMoreText }}
                  </p>


                  <button
                    class="btn btn-success green text-white d-flex"
                    @click="downloadFile(card.url,card.label)"
                  >
                    <img
                      src="@/assets/img/icono_descargar_programa.svg"
                      width="22"
                      height="22"
                      alt="Ícono descargar programas"
                      loading="lazy"
                    />
                    <b class="mx-2">{{ card.seeMoreButtonText }}</b>
                  </button>
                </div>

                <div class="col-12 col-md-6 mb-4 mb-md-0" v-if="card.action">
                  <p class="body2">
                    {{ card.actionText }}
                  </p>
                  <button
                    class="btn btn-success text-white green d-flex"
                    @click="goToRegister"
                  >
                    <img
                      src="@/assets/img/icono_analiza_tu_empresa.svg"
                      width="25"
                      height="25"
                      alt="Ícono analiza tu empresa"
                      loading="lazy"
                    />
                    <b class="mx-2">{{ card.actionButtonText }}</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img
      src="@/assets/img/bottom-curva-desktop.png"
      class="w-100"
      alt="Fondo programas 2 (desktop)"
      loading="lazy"
    />
  </section>
</template>

<script>
import store from "../../store";
import axios from "axios";
axios.defaults.withCredentials = true;

export default {
  name: "Programas",
  data() {
    return {
      data: {
        section: "programs",
        components: {
          title: "Programas",
          cards: [
            {
              id: 1,
              img: "img/programa-assessment-y-valorizacion.png",
              title: "Programa Assessment y Valorización",
              list: [
                {
                  id: 11,
                  text: "Análisis de la Gestión y Valorización de la empresa en función de la etapa de desarrollo en que se encuentra.",
                },
                {
                  id: 12,
                  text: " Propone un Plan de Acción para acortar las brechas detectadas y aumentar el valor de la empresa.",
                },
              ],
              seeMore: true,
              seeMoreText: "¿Quieres saber más?",
              seeMoreButtonText: "Descargar",
              action: true,
              actionText: "¡Comienza analizando tu empresa hoy!",
              actionButtonText: "Ahora",
              content: "",
            },
            {
              id: 2,
              img: "img/programas-2.png",
              title: "Programa Fundacional",
              content: `
          <p>Tiene por objetivo robustecer las fundaciones de la empresa, profesionalizarla y dejarla con capacidad de escalamiento.
          <br> <br>‎ Siguiendo el Modelo Wetforest 8X8 se alinea la estrategia con la organización, el capital humano e inversión requerida. Además se refuerza la gestión de las áreas finanzas, operaciones, comercial e innovación de la empresas.
          <br> <br>Este programa se configura con el cliente en función de la etapa de desarrollo empresarial en que se encuentra, las brechas detectadas y los hitos que se han definido alcanzar.</p>
          `,
              list: [],
              seeMore: true,
              seeMoreText: "¿Quieres saber más?",
              seeMoreButtonText: "Descargar",
              action: true,
              actionText: "¡Comienza analizando tu empresa hoy!",
              actionButtonText: "Ahora",
            },
            {
              id: 3,
              img: "img/programas-3.png",
              title: "Otros Programas",
              list: [
                {
                  id: 31,
                  text: "Estrategia",
                },
                {
                  id: 32,
                  text: " Organización y Capital Humano",
                },
                {
                  id: 33,
                  text: "Inversión",
                },
                {
                  id: 34,
                  text: "Finanzas ",
                },
                {
                  id: 35,
                  text: "Innovación",
                },
                {
                  id: 36,
                  text: " Operaciones",
                },
                {
                  id: 37,
                  text: "Comercial",
                },
                {
                  id: 38,
                  text: "  Directorios de Alto Rendimiento",
                },
              ],
              seeMore: true,
              seeMoreText: "¿Quieres saber más?",
              seeMoreButtonText: "Descargar",
              action: true,
              actionText: "¡Comienza analizando tu empresa hoy!",
              actionButtonText: "Ahora",
              content: "",
            },
          ],
        },
        language: "ES",
      },
    };
  },
  methods: {
    fetchData() {
      if (store.state.sections.programs) {
        this.data = store.state.sections.programs.components;
      }
    },

    goToRegister() {
      this.$router.push('/register')
    },

   async downloadFile(file,label) {
      let url = file;
      
        this.$store.commit('ActivaLoader', true);

      {
      await  axios
          .get(url, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = label;
            link.click();
            URL.revokeObjectURL(link.href);
            this.$store.commit('ActivaLoader', false);
          })
          .catch(
            this.$store.commit('ActivaLoader', true));
      }
    },
  },

  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* // Small devices (landscape phones, 576px and up)
 */

/* .action__button--text, .seeMore__button--text {
  display: none;
}

@media (min-width: 1200px) {
  .action__button:hover {
    & .action__button--text {
      display: block;
    }
  }
  .seeMore__button:hover {
    & .seeMore__button--text {
      display: block;
    }
  }
} */

.titleBanner {
  font-family: "InterMedium";
  font-size: 1.3vw;
  color: #414141;
  letter-spacing: 0, 65pt; /* para separar entgre letras */
  word-spacing: 0pt; /* para separacion entre palabras */
  display: block;
  margin-top: 5%;
}

.program-image {
  max-width: 250px;
  width: 100%;
}

.titleBannerlow {
  font-family: "GentiumBold";
  font-size: 3.4vw;
  color: #414141;
  letter-spacing: 0, 65pt; /* para separar entgre letras */
  word-spacing: 0pt; /* para separacion entre palabras */
  line-height: 104%; /* para la separacion entre lineas */
  display: block;
}

.Programas {
  position: relative;
}

.bg-light-green {
  background-color: #e7f8ec;
}

.sub-titulo {
  color: #414141;
  font-family: "InterMedium";
  font-size: 1.2vw;
  text-align: left;
}

.green {
  background-color: #23af4d !important;
}

.green:hover {
  background-color: #209c45 !important;
}

.container-programa {
  border: 1px solid #cae6d3;
  border-radius: 8px;
  background-color: #fff;
  /* padding: 1rem; */
  height: 100%;
  position: relative;
}

.container-programa .contain-title {
  box-shadow: 0px 3px 6px #14993c21;
  border-bottom: 1px solid #cae6d3;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/*.container-programa .contain-img {*/
/*  width: 25%;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding-top: 1rem;*/
/*}*/

/*.container-programa .contain-info {*/
/*  width: calc(100% - 400px);*/
/*}*/

.container-programa .contain-img img.img-2 {
  width: 350px;
  height: 280px;
}

.container-wetforest {
  padding-left: 8%;
  padding-right: 8%;
}

.container-programa .contain-title {
  box-shadow: 0px 3px 6px #14993c21;
  border-bottom: 1px solid #cae6d3;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.btn-success-programas {
  font-family: InterMedium;
  background-color: #23af4d !important;
  border-color: #23af4d !important;
  padding: 0.375rem 1.2rem;
  border-radius: 0.5rem;
  width: 64px;
}

@media (min-width: 982px) {
  .btn-success-programas:hover {
    width: 150px;
    transition: all ease 0.4s;
  }
}

h4 {
  text-align: left;
  font-size: 1.2rem;
}

ul {
  list-style: none; /* Remove default bullets */
}

.list-item::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #14993c; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

@media (max-width: 560px) {
  h4 {
    font-size: 1.5rem;
  }
}
</style>
